var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner-dialog-component" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片标题", prop: "fileName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.currentState === "view",
                          maxlength: "50",
                        },
                        model: {
                          value: _vm.ruleForm.fileName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "fileName", $$v)
                          },
                          expression: "ruleForm.fileName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接类型", prop: "linkType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: _vm.currentState === "view" },
                          model: {
                            value: _vm.ruleForm.linkType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "linkType", $$v)
                            },
                            expression: "ruleForm.linkType",
                          },
                        },
                        _vm._l(_vm.linkTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.ruleForm.linkType !== 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "图片链接", prop: "fileLink" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.currentState === "view" },
                            model: {
                              value: _vm.ruleForm.fileLink,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "fileLink", $$v)
                              },
                              expression: "ruleForm.fileLink",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布人", prop: "updateBy" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.ruleForm.updateBy,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "updateBy", $$v)
                          },
                          expression: "ruleForm.updateBy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  staticClass: "auto-height-item",
                  attrs: { label: "banner图片", prop: "photoIdListId" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.uploadLoading,
                          expression: "uploadLoading",
                        },
                      ],
                      staticClass: "upload-container",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          style: {
                            cursor:
                              _vm.currentState === "view"
                                ? "default"
                                : "pointer",
                          },
                          attrs: {
                            disabled: _vm.currentState === "view",
                            action: _vm.uploadPath,
                            "with-credentials": true,
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                          },
                        },
                        [
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.imageUrl },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handlePictureCardPreview.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                      _c("div", { staticClass: "tips-container" }, [
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "\n                温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。\n              "
                            ),
                          ]),
                          _c("div", [_vm._v("图片尺寸 750*384 像素")]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.currentState !== "view" &&
                                  _vm.ruleForm.fileId,
                                expression:
                                  "currentState !== 'view' && ruleForm.fileId",
                              },
                            ],
                            staticClass: "delete-icon",
                            on: { click: _vm.deleteHandler },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            modal: false,
            "close-on-press-escape": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }